import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutPrimary from '../templates/LayoutPrimary'

const NotFoundPage = () => {
  return (
    <LayoutPrimary>
      <Helmet>
        <title>Uh oh, something's missing here.</title>
      </Helmet>
      <div className='col-xxs-12'>
        <p>I'm making this by following the Gatsby Tutorial.</p>
      </div>
    </LayoutPrimary>
  )
}

export default NotFoundPage